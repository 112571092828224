#dayselector {
    border: 1px solid rgb(224, 224, 224);
    height: 40px;
    border-radius: 25px;
}


#dayselector div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(224, 224, 224, 0.5);
    width: 30px;
    height: 30px;
    border-radius: 50px;
}

#dayselector div:hover {
    background-color: rgb(192, 192, 192, 0.5);
    cursor: pointer;
}

#dayselector div:active {
    font-weight: bold;
}

#dayselector .selectedDay {
    background-color: #198754;
    color: #fff;
    font-weight: bold;
}

#dayselector .selectedDay:hover {
    background-color: #2fb677;
}

.capitalized {
    text-transform: capitalize;
}