.scrollMenu::-webkit-scrollbar {
    width: 10px;
}

.scrollMenu::-webkit-scrollbar-thumb {
    background: #222d32;
    border-radius: 20px;
}

.scrollMenu::-webkit-scrollbar-track {
    background: #666;
    border-radius: 20px;
}